import React, { useContext, useEffect, useMemo, useState } from 'react';

import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { useProtocolContext } from 'client/app/apps/protocols/context/ProtocolProvider';
import { useStepsContext } from 'client/app/apps/protocols/context/StepsProvider';
import { useWorkflowContext } from 'client/app/apps/protocols/context/WorkflowProvider';
import ProtocolElementInstancePanel from 'client/app/apps/protocols/ProtocolElementInstancePanel/ProtocolElementInstancePanel';
import { ResizableSidePanel } from 'client/app/apps/protocols/ResizableSidePanel';
import StepCard from 'client/app/apps/protocols/StepCard/StepCard';
import { ElementDetailsTabs } from 'client/app/components/ElementDetails/ElementDetails';
import WorkflowPreview from 'client/app/components/ExampleWorkflows/WorkflowPreview';
import { ParameterStateContext } from 'client/app/lib/rules/elementConfiguration/ParameterStateContext';
import { getElementDisplayName } from 'client/app/lib/workflow/elementConfigUtils';
import { ElementInstance } from 'common/types/bundle';
import Button from 'common/ui/components/Button';

export const DefineProtocol = () => {
  const [tabId, setTabId] = useState(ElementDetailsTabs.INPUTS);
  const [selectedObjectIds, setSelectedObjects] = useState<string[]>([]);
  const {
    displayDescription,
    updateProtocol,
    conflictDialog: protocolConflictDialog,
  } = useProtocolContext();
  const {
    workflowSchema,
    protocolSteps,
    selectedStep,
    createStep,
    updateStepName,
    updateStepInput,
    updateStepOutput,
    toggleStepInput,
    toggleStepOutput,
    deleteStep,
    deleteStepInput,
    deleteStepOutput,
    handleSelectStep,
  } = useStepsContext();
  const {
    id: workflowId,
    getElementInstance,
    handleUpdateSchema,
    conflictDialog: workflowConflictDialog,
  } = useWorkflowContext();
  const [elementInstance, setElementInstance] = useState<ElementInstance>();
  const elementInputs = useInputParameters(elementInstance);

  useEffect(() => {
    if (selectedObjectIds.length === 1) {
      const id = selectedObjectIds[0];
      const ei = getElementInstance(id);
      setElementInstance(ei);
    } else {
      setElementInstance(undefined);
    }
  }, [getElementInstance, selectedObjectIds]);

  useEffect(() => {
    // Workflow schema provides the functional aspect of a step, while protocol
    // steps provide the presentational aspect. So we need to update both. We
    // are safe to do this asynchronously since steps context is the single
    // source of truth and is independent of any entity mutations
    handleUpdateSchema(workflowSchema);
    updateProtocol({ protocol: { displayDescription, steps: protocolSteps } });
  }, [
    displayDescription,
    workflowSchema,
    protocolSteps,
    handleUpdateSchema,
    updateProtocol,
  ]);

  return (
    <Content>
      <ResizableSidePanel>
        <SidePanel>
          <Typography variant="h2">Define Protocol</Typography>
          {protocolSteps.map(step => (
            <StepCard
              key={step.id}
              step={step}
              tabId={tabId}
              setTabId={setTabId}
              active={step.id === selectedStep?.id}
              canDelete={protocolSteps.length > 1}
              onActivate={() => handleSelectStep(step.id)}
              onChangeName={updateStepName(step)}
              onChangeInput={(index, name) => updateStepInput(step)(index, { name })}
              onChangeOutput={updateStepOutput(step)}
              onDelete={() => deleteStep(step.id)}
              onDeleteInput={deleteStepInput(step)}
              onDeleteOutput={deleteStepOutput(step)}
            />
          ))}
          <Button variant="primary" color="primary" onClick={createStep}>
            + Add a step
          </Button>
        </SidePanel>
      </ResizableSidePanel>
      <PreviewWrapper>
        {workflowId && (
          <WorkflowPreview
            workflowId={workflowId}
            setSelectedObjects={setSelectedObjects}
            selectedObjectIds={selectedObjectIds}
          />
        )}
      </PreviewWrapper>
      {selectedStep && elementInstance && (
        <InstancePanelWrapper>
          <ProtocolElementInstancePanel
            activeStepId={selectedStep.id}
            protocolSteps={protocolSteps}
            schema={workflowSchema}
            elementInstanceId={elementInstance.Id}
            elementInstanceName={getElementDisplayName(elementInstance.element, false)}
            inputs={elementInputs || []}
            outputs={elementInstance.element.outputs}
            activeTab={tabId}
            onInputsChange={toggleStepInput(selectedStep, elementInstance.Id)}
            onOutputsChange={toggleStepOutput(selectedStep, elementInstance.Id)}
            onClose={() => setSelectedObjects([])}
          />
        </InstancePanelWrapper>
      )}
      {workflowConflictDialog || protocolConflictDialog}
    </Content>
  );
};

const useInputParameters = (instance?: ElementInstance) => {
  const { getStateForParameter } = useContext(ParameterStateContext);
  const { getElementParameterValue } = useWorkflowContext();
  return useMemo(() => {
    return instance?.element.inputs
      .filter(({ name, configuration }) => {
        // parameter visibility can be dependent on element configuration rules
        const {
          // assume parameter is enabled if state not found
          isVisible: isVisibleByConfigRules = true,
          isEnabled = true,
        } = getStateForParameter(instance.name, name) || {};

        // while other options are simply a yes / no setting
        const {
          isBlockedFromSchema = false,
          isInternalOnly = false,
          isVisible = true, // assume isVisible if configuration is undefined
        } = configuration || {};
        return (
          isEnabled &&
          isVisible &&
          isVisibleByConfigRules &&
          !isBlockedFromSchema &&
          !isInternalOnly
        );
      })
      .map(parameter => ({
        parameter,
        value: getElementParameterValue(instance, parameter),
      }));
  }, [getElementParameterValue, getStateForParameter, instance]);
};

const Content = styled('div')(() => ({
  position: 'relative',
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
}));

const SidePanel = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(5),
  padding: theme.spacing(6, 4),
}));

const PreviewWrapper = styled('div')(() => ({
  flex: 1,
  overflow: 'hidden',
}));

const InstancePanelWrapper = styled('div')(() => ({
  position: 'absolute',
  right: 0,
  zIndex: 10,
  margin: '8px 8px 8px 8px',
  height: 'calc(100vh - 122px)',
  overflow: 'hidden',
}));
