import {
  allConditions,
  allConsequences,
  getRuleMeta,
  RuleMetaKey,
  RuleMetaType,
} from 'client/app/apps/policy-library/RulesMeta';
import { EditorType } from 'common/elementConfiguration/EditorType';
import { capitalize } from 'common/lib/format';
import { LIQUID_TYPE_VAR, RULE_NAME_FIELD } from 'common/lib/liquidPolicies';
import { Markdown } from 'common/lib/markdown';
import { ColumnState, SheetState } from 'common/rules/evaluateSpreadsheetRules';
import { Option } from 'common/types/Option';
import { ColumnConfiguration } from 'common/types/spreadsheet';
import { DataTable } from 'common/types/spreadsheetEditor';
import { getSensibleMeasurementUnits } from 'common/ui/components/ParameterEditors/unitRegistry';
import { TableConfiguration } from 'common/ui/components/Table';

export const conditionOptions: Option<RuleMetaKey>[] = Object.entries(allConditions)
  .map(([key, { label }]) => ({ label: capitalize(label), value: key as RuleMetaKey }))
  .filter(option => option.value !== LIQUID_TYPE_VAR);

export const consequenceOptions: Option<RuleMetaKey>[] = Object.entries(
  allConsequences,
).map(([key, { label }]) => ({ label: capitalize(label), value: key as RuleMetaKey }));

export const initTableConfig: TableConfiguration = {
  canAddColumns: true,
  columns: [
    makeColumnConfig({
      name: RULE_NAME_FIELD,
      displayName: 'Rule Name',
      description:
        'Name of the rule in a liquid policy.\n\nCannot have spaces or special characters “$”, “%”, “*”, “@”, “!” etc.',
      editor: {
        type: EditorType.STRING,
        additionalProps: null,
      },
    }),
  ],
};
export const initDataTable: DataTable = {
  schema: {
    fields: [{ name: RULE_NAME_FIELD, type: 'string' }],
  },
  data: [{ [RULE_NAME_FIELD]: 'Rule_1' }],
};
export const getTableStateFromConfig = (config: TableConfiguration): SheetState => ({
  columns: config.columns.reduce((result, nextColumn) => {
    result[nextColumn.name] = { isRequired: nextColumn.name === RULE_NAME_FIELD };
    return result;
  }, {} as Record<string, ColumnState>),
});

type RuleMetaDataType = keyof typeof ruleValueEditorConfigMap;

const ruleValueEditorConfigMap = {
  $string_array: {
    type: EditorType.STRING,
    additionalProps: null,
  },
  $string: {
    type: EditorType.STRING,
    additionalProps: null,
  },
  $bool: {
    type: EditorType.TOGGLE,
    additionalProps: null,
  },
  $float: {
    type: EditorType.FLOAT,
    additionalProps: null,
  },
  $unsigned_int: {
    type: EditorType.INT,
    additionalProps: null,
  },
  $duration: makeMeasurementConfig(getSensibleMeasurementUnits('Time'), 's'),
  $flow_rate: makeMeasurementConfig(
    getSensibleMeasurementUnits('Volume').flatMap(l =>
      getSensibleMeasurementUnits('Time').map(t => `${l}/${t}`),
    ),
    'ul/s',
  ),
  $length: makeMeasurementConfig(getSensibleMeasurementUnits('Length'), 'mm'),
  $speed: makeMeasurementConfig(
    getSensibleMeasurementUnits('Length').flatMap(l =>
      getSensibleMeasurementUnits('Time').map(t => `${l}/${t}`),
    ),
    'mm/s',
  ),
  $volume: makeMeasurementConfig(getSensibleMeasurementUnits('Volume'), 'ul'),
  $config: makeDropdownConfig(['auto', 'on', 'off']),
  $proxy_liquid: makeDropdownConfig([
    'FastAqueous',
    'Glycerol',
    'Ethanol',
    'System',
    'Robo',
    'Aqueous',
    'Surfactant',
    'Serum',
    'DMSO',
  ]),
  $well_reference: makeDropdownConfig(['well_bottom', 'well_top', 'liquid_level']),
  $well_zone: makeDropdownConfig(['bottom', 'liquid', 'top']),
  $device_manufacturer: makeDropdownConfig(['Gilson', 'Hamilton', 'Labcyte', 'Tecan']),
  $device_model: makeDropdownConfig([
    'Echo520',
    'Echo525',
    'Echo550',
    'Echo555',
    'Echo650',
    'Echo655',
    'Evo',
    'Fluent',
    'Pipetmax',
    'Star',
  ]),
};

function makeMeasurementConfig(
  units: string[],
  defaultUnit: string,
): ColumnConfiguration['editor'] {
  return {
    type: EditorType.MEASUREMENT,
    additionalProps: {
      editor: EditorType.MEASUREMENT,
      units,
      defaultUnit,
    },
  };
}

function makeDropdownConfig(options: string[]): ColumnConfiguration['editor'] {
  return {
    type: EditorType.DROPDOWN,
    additionalProps: {
      editor: EditorType.DROPDOWN,
      options: options.map(option => ({ label: option, value: option })),
      useDynamicOptions: false,
    },
  };
}

export const getRuleValueEditorConfig = (
  type: RuleMetaDataType,
): ColumnConfiguration['editor'] =>
  ruleValueEditorConfigMap[type] as ColumnConfiguration['editor'];

export function makeColumnConfig({
  name,
  displayName,
  description,
  editor,
  dataType = 'string',
}: {
  name: string;
  displayName: string;
  description: string;
  dataType?: ColumnConfiguration['dataType'];
  editor: ColumnConfiguration['editor'];
}): ColumnConfiguration {
  return {
    name,
    displayName,
    description: description as Markdown,
    dataType,
    editor,
    anthaType: 'string',
    dragToFillBehaviour: 'copy',
    hasTrailingGap: false,
  };
}
export function getColumnDataType(
  type: RuleMetaDataType,
): ColumnConfiguration['dataType'] {
  switch (type) {
    case '$bool':
      return 'boolean';
    case '$float':
    case '$unsigned_int':
      return 'number';
    default:
      return 'string';
  }
}

/**
 * Adds column to the Rules `DataTable`:
 * - adds row to `data` if there were no rows before
 * - adds column to `schema`
 * - add configuration of the new column to the `TableConfiguration`
 *
 * Mutates inputs: `table` & `config`
 */
export function addColumn({
  table,
  config,
  column,
}: {
  table: DataTable;
  config: TableConfiguration;
  column: { type: RuleMetaType; name: RuleMetaKey };
}) {
  const meta = getRuleMeta(column.type, column.name);
  const metaType = meta.type as RuleMetaDataType;

  // Add column to schema
  table.schema.fields.push({
    name: column.name,
    type: getColumnDataType(metaType),
  });

  // Add row if there were no rows before
  if (table.data.length === 0) {
    table.data.push({});
  }

  // Initialise column value in each row
  for (const row of table.data) {
    row[column.name] = null;
  }

  // Add column configuration
  config.columns.push(
    makeColumnConfig({
      name: column.name,
      displayName: `${capitalize(meta.label)} (${column.type})`,
      description: meta.description,
      editor: getRuleValueEditorConfig(metaType),
      dataType: getColumnDataType(metaType),
    }),
  );
}
